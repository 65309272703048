.campaigns {
  flex: 5;
  /* padding: 90px 25px 90px 125px; */
}

.campaigndetailscontainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
}

.campaigndetailschart {
  /* max-width: 500px; */
}

.campaignItem {
  width: 200px;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 40px;
  display: flex;
}

.campaignForm {
  display: flex;
  flex-wrap: wrap;
}

.campaignItem span.mandatory {
  margin-bottom: 10px;
  font-weight: 600;
  color: red;
  font-size: 16px;
}

.campaignItem > label {
  margin-bottom: 15px;
  font-weight: 600;
  color: #003a41;
  font-size: 16px;
}


.dataDescriptionSM {
  font-weight: 300;
  color: #757575;
  font-size: 16px;
}

.dataDescriptionLG {
  font-weight: 300;
  color: #757575;
  font-size: 30px;
}

.bodycontainer {
  width: 100%;
  margin-top:30px;
}

.bodyItem {
  width: 100%;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 40px;
  max-width: 1200px;
}

.bodyItem span.mandatory {
  margin-bottom: 10px;
  font-weight: 600;
  color: red;
  font-size: 16px;
}

.bodyItem > label {
  margin-bottom: 15px;
  font-weight: 600;
  color: #003a41;
  font-size: 16px;
  width: 100%;
  display: flex;
}

/* .userTables{
    display: flex;
    justify-content: space-between;
}

.userTablesTop{
    flex: 2;
    margin-right: 15px;
}

.userTablesBottom{
    flex: 2;
    margin-left: 15px;
} */
