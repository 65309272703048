@import url('https://fonts.googleapis.com/css?family=Manrope:regular,Light,Bold,ExtraBold,SemiBold,Medium,ExtraLight,italic');

.sendNow {
  flex: 5;
  padding: 100px 40px;
}

.schedule {
  flex: 5;
  padding: 100px 40px !important;
  margin: 0px 65px !important;
}

.messaging-container {
  flex: 5;
  padding: 100px 40px;
}

.datepicker > div {
  height: 50px;
  padding: 5px 5px 5px 15px;
  border: 1px solid #d8d5d6;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 16px;
}

.mainContentWrapper {
  max-width: 1400px;
  margin: 0px auto;
}

.sendNowForm {
  padding: 20px 30px;
  border: 1px solid #d8d5d6;
  border-radius: 15px;
  max-width: 1400px;
  margin: 15px auto;
}

.sendNowItem {
  width: auto !important;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 20px;
}

.sendNowItem > label {
  margin-bottom: 15px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 28px;
  color: #2c2126;
}

.nameimage {
  background-image: url(../../images/campaigname.svg) !important;
  background-repeat: no-repeat;
  background-position: top 5px left 5px;
  background-size: 40px 40px;
}

.contactsimage {
  background-image: url(../../images/contacts.svg) !important;
  background-repeat: no-repeat;
  background-position: top 5px left 5px;
  background-size: 40px 40px;
}

.sendNowItemContainer {
  width: 100%;
  justify-content: space-around;
  display: flex;
}

.sendNowItemContainerleft {
  width: 50%;
}

.sendNowItemContainerleft > label {
  margin-bottom: 15px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #2c2126;
}

.sendNowItemContainerright {
  width: 50%;
  justify-content: flex-end;
  display: flex;
}

.sendNowItemContainerright > label {
  margin-bottom: 15px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #2c2126;
}

.shortwidth {
  width: 50%;
}

.shortwidthtoggle {
  width: 50%;
  flex-direction: inherit;
}

.sendNowItem .templateButtonContainer > label {
  margin-bottom: 15px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 28px;
  color: #2c2126;
}

.sendNowItem span.mandatory {
  margin-bottom: 10px;
  font-weight: 600;
  color: red;
  font-size: 16px;
}

.sendNowItem > input {
  width: 100%;
  height: 50px;
  padding: 5px 5px 5px 15px;
  border: 1px solid #d8d5d6;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 16px;
}

.sendNowItem > input::placeholder {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #b5b3b4;
}

.sendNowItem > textarea {
  height: 250px;
  padding: 15px 20px 15px 20px;
  border: 1px solid #d8d5d6;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 16px;
}

.sendNowItem > textarea::placeholder {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #b5b3b4;
}

.sendNowButton {
  border-radius: 10px;
  background-color: #ef5663;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  padding: 7px 10px;
  height: 55px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  max-width: 200px;
  margin-right: 20px;
}

.previewButton {
  border-radius: 10px;
  background-color: #2c87de;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  padding: 7px 25px;
  height: 55px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  max-width: 200px;
  margin-right: 20px;
}

.flex-row {
  flex-direction: row !important;
}

.ck-content {
  min-height: 300px;
}

/*.ck-content > *, .ck-content > p, .ck-content > .image{
    all: unset !important;
}*/

/*.ck-content > p, .ck-content .table > * {
    all: unset !important;
}*/

.scheduleForm {
  /* display: flex; 
    flex-wrap: wrap; */
}

.scheduleItem {
  width: 600px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 40px;
}

.scheduleItem > label {
  margin-bottom: 15px;
  font-weight: 600;
  color: #003a41;
  font-size: 16px;
}

.scheduleItem .templateButtonContainer > label {
  margin-bottom: 0px;
  font-weight: 600;
  color: #003a41;
  font-size: 16px;
}

.scheduleItem span.mandatory {
  margin-bottom: 10px;
  font-weight: 600;
  color: red;
  font-size: 16px;
}

.scheduleItem > input {
  height: 50px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #f9fafb;
  font-size: 16px;
}

.scheduleItem .react-datetime-picker__wrapper {
  height: 40px !important;
  padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #f9fafb;
  font-size: 16px;
  color: #757575;
}

.scheduleItemFile {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 600px;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 40px;
}

.scheduleItemFileText {
  color: #757575;
  font-size: 14px;
  padding: 5px;
}

.scheduleItemFileText a {
  color: #1d2740;
  font-size: 14px;
}

.scheduleItemFile > input {
  height: 50px;
  padding: 5px 5px 5px 0px;
  font-size: 16px;
}

.scheduleItemFile label {
  margin-bottom: 15px;
  font-weight: 600;
  color: #003a41;
  font-size: 16px;
}

.scheduleItemFile span.mandatory {
  margin-bottom: 10px;
  font-weight: 600;
  color: red;
  font-size: 16px;
}

.scheduleItem > textarea {
  height: 250px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #f9fafb;
  font-size: 16px;
}

.scheduleButton {
  width: 100%;
  border-radius: 5px;
  background-color: #1d2740;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  font-weight: 600;
  padding: 7px 10px;
  color: white;
  height: 46px;
  font-size: 18px;
}

.flex-row {
  flex-direction: row !important;
}

.w-750 {
  width: 750px !important;
  gap: 20px;
}

.newUserItem {
  width: 600px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 40px;
}

.newUserItem > label {
  margin-bottom: 10px;
  font-weight: 600;
  color: #003a41;
  font-size: 16px;
}

.hidden-container {
  border: 1px solid #d8d5d6;
  padding: 0px;
  margin: 18px !important;
  border-radius: 10px;
}

.select_template_div {
  float: right;
}

.ckeditor_textarea {
  margin-right: 20px;
}

.select_template_div .ck-rounded-corners {
  margin-right: 20px;
}

.extendModalSize .MuiDialog-paper {
  min-width: 60% !important;
}

.importContactInModal .MuiDialog-paper {
  min-width: 60% !important;
  min-height: 450px;
}

.width-auto {
  width: auto !important;
}

.pr-20 {
  padding-right: 20px;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.both-switch {
  margin-left: 42px !important;
  margin-top: 61px !important;
}

.ptb-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.main-grid-container .css-9f4jk-MuiGrid-root > .MuiGrid-item {
  padding-top: 0px !important;
  margin-bottom: -25px !important;
}

.form-label {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  color: #2c2126;
}

.template-button {
  font-size: 14px;
  margin-right: 38px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.pt-47 {
  padding-top: 47px !important;
}

.ml-15 {
  margin-left: 15px;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.border-box {
  border: 1px solid #d8d5d6;
  padding: 15px;
  border-radius: 10px;
}

.mt-25 {
  margin-top: 25px !important;
}

.mandatory {
  margin-bottom: 10px;
  font-weight: 600;
  color: red;
  font-size: 16px;
}

.mb--40 {
  margin-bottom: -40px !important;
}

.contacts-selected-div {
  margin-top: 25px;
  margin-bottom: 25px;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pb-45 {
  padding-bottom: 45px;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.text-right {
  text-align: right !important;
}

.dialogTitle-component button {
  /*padding: 0px !important;*/
}

.sub-container > .MuiGrid-item {
  padding-top: 0px !important;
  margin-bottom: -25px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.ckeditor-grid .ck-editor__top,
.ckeditor-grid .ck-editor__main {
  width: 98% !important;
  border-radius: 5px !important;
}

.ckeditor-grid .ck-source-editing-area {
  max-width: 1057px !important;
  min-height: 300px;
}

.ck-toolbar {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.ck-editor__editable_inline {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.mb--40 {
  margin-bottom: -40px !important;
}

.mt--5 {
  margin-top: -5px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.import-contact-header-grid {
  /* border-bottom: 1px solid #cfd2db; */
  padding: 10px 0px 10px 0px;
  margin-bottom: 20px !important;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #4c4448;
}

.pl-60 {
  padding-left: 60px !important;
}

/* Set the minimum height of Classic editor */
.ck.ck-editor__editable_inline:not(.ck-editor__nested-editable) {
  min-height: 400px;
}

/* Styles to render an editor with a sidebar for comments & suggestions */
.container {
  display: flex;
  flex-direction: row;
}

.document-outline-container {
  background-color: #f3f7fb;
  width: 200px;
}

.sidebar {
  width: 320px;
}

#editor-container .ck.ck-editor {
  width: 860px;
}

#editor-container .sidebar {
  margin-left: 20px;
}

#editor-container .sidebar.narrow {
  width: 30px;
}

/* Keep the automatic height of the editor for adding comments */
.ck-annotation-wrapper .ck.ck-editor__editable_inline {
  min-height: auto;
}

/* Styles for viewing revision history */
#revision-viewer-container {
  display: none;
}

#revision-viewer-container .ck.ck-editor {
  width: 860px;
}

#revision-viewer-container .ck.ck-content {
  min-height: 400px;
}

#revision-viewer-container .sidebar {
  border: 1px #c4c4c4 solid;
  margin-left: -1px;
  width: 320px;
}

#revision-viewer-container .ck.ck-revision-history-sidebar__header {
  height: 39px;
  background: #fafafa;
}

/* .hidden {
    display: none !important;
} */

.ck.ck-dropdown .ck-dropdown__arrow {
  z-index: auto !important;
}

.importContactInModal .popup-inner .close-btn {
  top: 5px !important;
}
