.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-inner {
  position: relative;
  width: 100%;
  max-width: 650px;
  padding: 30px;
  border-radius: 10px;
  background-color: white;
}

.popup-inner .close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
}
