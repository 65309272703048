.analytics{
    flex: 5;
    padding: 90px 25px 90px 125px;
}

.filterRowDD {
    /*display: flex;
    justify-content: flex-end;*/
    width: 390px;
}
.filterRow {
    display: flex;
    justify-content: flex-end;
}

.flexBox {
    display: flex;
}

.rightside-btn {
    float: right;
    margin-top: 50px;
}
.MuiDataGrid-root .MuiDataGrid-menuIcon {
    visibility: visible !important;
    
}
.css-ptiqhd-MuiSvgIcon-root {
    font-size: 1.6em !important;

}