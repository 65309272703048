.users{
    flex: 5;
    padding: 90px 25px 90px 125px;
}

.userListUser{
    display: flex;
    align-items: center;
}

.userListActions{
    display: flex;
    align-items: center;
}

.userListImage{
    height: 32px;
    width: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}

.userListEdit{
    border: none;
    border-radius: 10px;
    padding:5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.userListDelete{
    color: red;
    cursor: pointer;

}

.invalidList{  font-size: 0.875rem !important;
    font-weight: 400 !important;
    line-height: 1.43 !important;
    letter-spacing: 0.01071em !important;
    padding: 0px !important;
    text-transform: lowercase !important;
    min-width: 0px !important;}

.userListDeleteYellow{
    color: #ffa413 !important;
    cursor: pointer;
    font-size: 1.5rem !important;
    width: 1em !important;
    height: 1em !important;
    stroke: none;
}

.userListDeleteYellow:hover{
    color: #facc15 !important;
    cursor: pointer;
    font-size: 1.5rem !important;
    width: 1em !important;
    height: 1em !important;
    stroke: none;
}

.userListDeleteRed{
    color: #e23c09 !important;
    cursor: pointer;
    font-size: 1.5rem !important;
    width: 1em !important;
    height: 1em !important;
    stroke: none;
}

.userListDeleteRed:hover{
    color: #e23c09 !important;
    cursor: pointer;
    font-size: 1.5rem !important;
    width: 1em !important;
    height: 1em !important;
    stroke: none;
}

.userListDeletePurple{
    color: #a700ff !important;
    cursor: pointer;
    font-size: 1.5rem !important;
    width: 1em !important;
    height: 1em !important;
    stroke: none;
}

.userListDeletePurple:hover{
    color: #a700ff !important;
    cursor: pointer;
    font-size: 1.5rem !important;
    width: 1em !important;
    height: 1em !important;
    stroke: none;
}

.userListDeleteGreen{
    color: #22C55E !important;
}
.userListDeleteGreen:hover{
    color: #22C55E !important;
}

.userTablesTop{
    margin-bottom: 40px;
}

.userTablesBottom{
    margin-bottom: 40px;
}

.ml-15 {
    margin-left: 15px;
}

/* .users .MuiDataGrid-root{
    height: 40vh !important;
} */

.color-gray {
    color: rgba(0, 0, 0, 0.87) !important;
}


.parentAccordion {
    margin : 15px 0px 15px 0px;
    border: 1px solid #ccc;
}

.contactlist-page .MuiPaper-rounded {
    border-radius: 10px !important;
}

.displayborder{
    border-bottom: 1px solid #ccc;
}

.MuiAutocomplete-inputRoot {
    height: 50px;
    border-radius: 10px !important;
    padding: 0px 10px !important;
}

.contactlist-page .MuiAccordionDetails-root .MuiDataGrid-root {
    border: none;
}

.contactlist-page .MuiAccordion-root {
    box-shadow: none !important;
    border: 1px solid #D8D5D6;
}

.contactlist-action-buttons {
    display: flex;
    position: absolute;
    right: 0px;
    margin-right: 35px;
}

.contactlist-page .MuiAccordionSummary-root {
    width: 100%;
}