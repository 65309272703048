.invalid > span {
  display: flex;
  align-items: center;
  padding-left: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #62585d;
  opacity: 1 !important;
}

.invalid {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 !important;
}

.valid > span {
  display: flex;
  align-items: center !important;
  color: green;
  padding-left: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  opacity: 1 !important;
}

.valid {
  display: flex;
  align-items: center;
  justify-content: center;
}
