/* .overview{
    flex:5;
    padding: 90px 25px 90px 125px;
}

.overviewWidget{
    display: flex;
    margin: 20px;
}


.overview .MuiDataGrid-root{
    background-color: #ffffff;
    border-radius: 8px;
    }

    .overview .MuiDataGrid-columnsContainer{

        border-radius:  20px 20px 0px 0px;
        }

.speedo-text-container {
    padding: 0px 40px;
    text-align: center;
}

.completion-container{
    display: flex;
    align-items: center;
}


.completion-container .response-block{
    width: 11px;
    height: 28px;
    background: #13AAFF;
    border-radius: 20px;
    margin-right: 10px;
}

.completion-response-chart {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 40px;
    width:100% !important; 
    height: auto !important;
}

.title{
    font-size: 20px;
    font-weight: 500;
}
.completion-response-chart .chart-content{
    text-align: center;
}

.completion-response-chart .chart-content .chart-title{
    margin-bottom: 30px;
}

.bar-chart{
    width: inherit !important;
}
     */
.MuiDataGrid-columnsContainer {
  border-radius: 20px 20px 0 0;
}
