.custom-menu {
  color: black;
  display: flex;
  flex-direction: column;
}

.item-col-select {
  border-bottom: 1px solid #c1c1c1;
  cursor: pointer;
  padding-bottom: 10px;
  display: flex;
}

.custom-file-input {
  position: absolute;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  width: 150px;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
