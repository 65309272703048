.campaigns {
    flex: 5;
    /* padding: 130px 40px; */
}

.campaigndetailscontainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 50px;
}


.campaigndetailschart {
    border: 1px solid rgb(211, 209, 209);
    border-radius: 10px;
    margin-right: 25px;
    padding-top: 10px;
    padding-left: 15px;
    position: relative;
  
}

.campaignItem {

    width: 200px;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 40px;
    display: flex;
}

.circularBarBlock{
    display: flex;
    justify-content:space-between;
    align-items: center;
    border: 1px solid rgb(211, 209, 209);
    padding-inline: 20px;
    border-radius: 10px;
    width: 350px ;
    margin: 10px;

}
.campaignForm {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 200px;
    gap: 10px; 
}

.main-pie-chart{
    display: flex;
    justify-content:space-between;
    flex-direction: column;
    align-items: center;
    width: 370px ;
    border: 1px solid rgb(211, 209, 209);
    padding-inline: 20px;
    border-radius: 10px;
    margin: 10px; 
    grid-row: span 2; 
}

@media screen and (max-width: 1600px) {
    .main-pie-chart{
        width: 350px ;
    }
}

@media screen and (max-width: 1080px) {
    .campaignForm {
      grid-template-columns: repeat(3, 1fr);
    }
  }

@media screen and (max-width: 768px) {
    .campaignForm {
      grid-template-columns: repeat(2, 1fr);
    }
  }

@media screen and (max-width: 512px) {
    .campaignForm {
      grid-template-columns: repeat(1, 1fr);
    }
    .main-pie-chart{
        width: 400px ;
    }
    .circularBarBlock{
        width: 400px ;
    }
  }

.campaignItem span.mandatory {
    margin-bottom: 10px;
    font-weight: 600;
    color: red;
    font-size: 16px;
}

.campaignItem>label {
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 16px;
    color: #003a41;
}
.circularBarBlock>div>label {
    margin-bottom: 15px;
    font-weight: 600;
    color: #003a41;
    font-size: 16px;
}
.circularBarBlock>label {
    margin-bottom: 15px;
    font-weight: 600;
    color: #003a41;
    font-size: 16px;
}
.dataDescriptionSM {
    font-weight: 300;
    color: #757575;
    font-size: 16px;
}


.dataDescriptionLG {
    font-weight: 300;
    color: #757575;
    font-size: 30px;
}

.dataCountDescription{ 
    top:53%;
    left:50%;
    font-weight:700;
    font-size: 1em;
    color: #757575;
    position: absolute;
    transform: translate(-50%, -50%) }

.bodycontainer {
    width: 100%;
}


.bodyItem {

    width: 100%;
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 40px;
    max-width: 1200px;

}


.bodyItem span.mandatory {
    margin-bottom: 10px;
    font-weight: 600;
    color: red;
    font-size: 16px;
}

.bodyItem>label {
    margin-bottom: 15px;
    font-weight: 600;
    color: #ffd200;
    font-size: 16px;
    width: 100%;
    display: flex;
}

.actionElementButton:disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: grey;
    z-index: 10;
}

/* .userTables{
    display: flex;
    justify-content: space-between;
}

.userTablesTop{
    flex: 2;
    margin-right: 15px;
}

.userTablesBottom{
    flex: 2;
    margin-left: 15px;
} */