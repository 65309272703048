@import url('https://fonts.googleapis.com/css?family=Manrope:regular,Light,Bold,ExtraBold,SemiBold,Medium,ExtraLight,italic');
.sendNow {
  flex: 5;
  padding: 100px 40px;
}

.schedule {
  flex: 5;
  padding: 100px 40px !important;
  margin: 0px 65px !important;
}

.sendNowFormPopup {
  padding: 20px 30px;
  max-width: 1400px;
  margin: 15px;
}

.sendNowItemWP {
  width: 100% !important;
  display: flex;
  flex-direction: column;
}
.sendNowItemWP > label {
  margin-bottom: 15px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px !important;
  line-height: 28px;
  color: #2c2126;
}

.nameimage {
  background-image: url(../../images/campaigname.svg) !important;
  background-repeat: no-repeat;
  background-position: top 5px left 5px;
  background-size: 40px 40px;
}
.contactsimage {
  background-image: url(../../images/contacts.svg) !important;
  background-repeat: no-repeat;
  background-position: top 5px left 5px;
  background-size: 40px 40px;
}
.sendNowItemContainer {
  width: 100%;
  justify-content: space-around;
  display: flex;
}
.sendNowItemContainerleft {
  width: 50%;
}
.sendNowItemContainerleft > label {
  margin-bottom: 15px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #2c2126;
}
.sendNowItemContainerright {
  width: 50%;
  justify-content: flex-end;
  display: flex;
}
.sendNowItemContainerright > label {
  margin-bottom: 15px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #2c2126;
}
.shortwidth {
  width: 50%;
}
.shortwidthtoggle {
  width: 50%;
  flex-direction: inherit;
}
.sendNowItemWP .templateButtonContainer > label {
  margin-bottom: 15px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 28px;
  color: #2c2126;
}

.sendNowItemWP span.mandatory {
  margin-bottom: 10px;
  font-weight: 600;
  color: red;
  font-size: 16px;
}
.sendNowItemWP > input {
  width: 100%;
  height: 50px;
  padding: 5px 5px 5px 15px;
  border: 1px solid #d8d5d6;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 16px;
}

.sendNowItemWP > input::placeholder {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #b5b3b4;
}

.sendNowItemWP > textarea {
  height: 250px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #d8d5d6;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 16px;
}

.sendNowItemWP > textarea::placeholder {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #b5b3b4;
}

.sendNowButton {
  border-radius: 10px;
  background-color: #ef5663;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  padding: 7px 10px;
  height: 55px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  max-width: 200px;
}

.archiveButton {
  width: 100%;
  border-radius: 10px;
  background-color: green;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  padding: 7px 10px;
  height: 55px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  max-width: 200px;
}

.flex-row {
  flex-direction: row !important;
}

.ck-content {
  min-height: 300px;
}

.scheduleForm {
  /* display: flex; 
    flex-wrap: wrap; */
}
.scheduleItem {
  width: 600px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 40px;
}
.scheduleItem > label {
  margin-bottom: 15px;
  font-weight: 600;
  color: #003a41;
  font-size: 16px;
}

.scheduleItem .templateButtonContainer > label {
  margin-bottom: 0px;
  font-weight: 600;
  color: #003a41;
  font-size: 16px;
}

.scheduleItem span.mandatory {
  margin-bottom: 10px;
  font-weight: 600;
  color: red;
  font-size: 16px;
}
.scheduleItem > input {
  height: 50px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #f9fafb;
  font-size: 16px;
}

.scheduleItem .react-datetime-picker__wrapper {
  height: 40px !important;
  padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #f9fafb;
  font-size: 16px;
  color: #757575;
}

.scheduleItemFile {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  max-width: 600px;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 40px;
}

.scheduleItemFileText {
  color: #757575;
  font-size: 14px;
  padding: 5px;
}
.scheduleItemFileText a {
  color: #1d2740;
  font-size: 14px;
}

.scheduleItemFile > input {
  height: 50px;
  padding: 5px 5px 5px 0px;
  font-size: 16px;
}

.scheduleItemFile > label {
  margin-bottom: 15px;
  font-weight: 600;
  color: #003a41;
  font-size: 16px;
}

.scheduleItemFile span.mandatory {
  margin-bottom: 10px;
  font-weight: 600;
  color: red;
  font-size: 16px;
}

.scheduleItem > textarea {
  height: 250px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #f9fafb;
  font-size: 16px;
}

.scheduleButton {
  width: 100%;
  border-radius: 5px;
  background-color: #1d2740;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  font-weight: 600;
  padding: 7px 10px;
  color: white;
  height: 46px;
  font-size: 18px;
}

.flex-row {
  flex-direction: row !important;
}

.newUserItem {
  width: 100% !important;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 40px;
}
.newUserItem > label {
  margin-bottom: 10px;
  font-weight: 600;
  color: #003a41;
  font-size: 16px;
}

.wp-wrapper-cls {
  max-height: 350px;
  overflow: scroll;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.icon-cls {
  display: flex;
  justify-content: space-between;
}

.icon-cls-temp {
  display: flex;
  padding-left: 10px;
}

.wp-main-label-temp {
  padding-left: 10px;
  font-size: 16px;
  font-weight: bold;
}

/* .wp-list-cls:hover {
  cursor: pointer;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
} */

.form-main-cls {
  padding: 20px;
}

.wp-list-cls {
  padding: 20px 10px 10px 10px;
  border-bottom: 1px solid #c1c1c1;
  cursor: pointer;
}

.wp-main-label {
  font-size: 16px;
  font-weight: bold;
}

.wp-channel-number {
  font-weight: bold;
  font-size: 15px;
  color: #999999;
}

.wp-main-wrapper {
  margin-top: 30px;
}

.wp-text-heading {
  font-weight: bold;
  font-size: 23px;
  padding-left: 10px;
  padding-right: 5px;
}

.wp-user-info {
  padding: 35px 0 35px 0 !important;
}

.wp-user-participant {
}

.wp-msg-via {
  display: flex;
  border: 1px solid #c1c1c1;
  border-radius: 5px;
  padding: 5px;
}

.wp-channel-no {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wp-label {
  font-weight: bold;
}

.contact-list {
}

.add-name > input {
  height: 50px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #f9fafb;
  font-size: 16px;
  width: 100%;
}
.add-name > label {
  margin-bottom: 10px;
  font-weight: 600;
  color: #003a41;
  font-size: 16px;
}
.add-name {
  padding: 20px;
}
