@import url('https://fonts.googleapis.com/css?family=Manrope:regular,Light,Bold,ExtraBold,SemiBold,Medium,ExtraLight,italic');

@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  background-color: #ffffff !important;
}

a {
  text-decoration: none !important;
}

.br-10 {
  border-radius: 10px !important;
}

/* New class start from here */
.btn-cls {
  width: 100%;
  border-radius: 8px;
  background-color: #f05865;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  padding: 7px 10px;
  color: white;
  height: 60px;
  font-size: 18px;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
}

.input-field-cls {
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  font-size: 1rem;
  padding: 1rem;
  height: 3rem;
  font-family: 'Manrope', sans-serif;
  background-color: #f9fafc;
  color: #000;

  outline: none;
}

.MuiTypography-root {
  font-family: 'Manrope', sans-serif !important;
  font-size: 15px !important;
}

input::placeholder {
  color: #4c4448 !important;
}
.sidebar-menu {
  border-radius: 12px;
}
.sidebar-menu:hover {
  background: #ef5864;
}

.sidebar-menu:hover #menuText {
  color: white;
}

.sidebar-menu:hover #menuIcon {
  filter: brightness(0) invert(1);
}

.sidebar-menu-active:hover {
  background: #ef5864;
}

.MuiListItemButton-root:hover #subMenuText {
  color: white;
}

.MuiListItemButton-root:hover #ExpandMoreIcon {
  color: white !important;
}

.MuiListItemButton-root:hover #ExpandLessIcon {
  color: white !important;
}

.MuiListItemButton-root:hover #subMenuIcon {
  filter: brightness(0) invert(1);
}

.MuiListItemButton-root:hover #iconImg {
  filter: brightness(0) invert(1);
}

.subMenuList:hover {
  background: #f0edee;
  border-radius: 12px;
}
/* Firefox */
* {
  scrollbar-width: none;
  scrollbar-color: #c1c1c1 #ffffff;
}

/* width */
*::-webkit-scrollbar {
  width: 4px !important;
}

/* Track */
*::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
/* *::-webkit-scrollbar-thumb {
  background: transparent !important;
} */

/* Handle on hover */
/* *::-webkit-scrollbar-thumb:hover {
  background: #da1414;
} */

.activity-dashboard-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 20px;
}
.activity-dashboard-wrapper .search-wrapper {
  width: 100%;
}
.activity-dashboard-wrapper .filter-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
@media(max-width:1536px){
  .activity-dashboard-wrapper .search-wrapper {
    max-width: 300px;
  }
}
@media (min-width: 1537px) {
  .activity-dashboard-wrapper {
    flex-direction: row-reverse;
  }
  .activity-dashboard-wrapper .search-wrapper {
    width: 20%;
  }
  .activity-dashboard-wrapper .filter-wrapper {
    width: 80%;
    padding-right: 20px;
  }
  .activity-min-height {
    min-height: 52px;
  }
}

/* New class end from here */

.MuiDrawer-paperAnchorDockedLeft {
  border: none;
}

a > #menuIcon:hover {
  filter: 'brightness(0) invert(1)';
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.MuiDrawer-paper {
  z-index: 1200 !important;
}

.link {
  text-decoration: none;
  color: inherit;
}

span.MuiButton-endIcon {
  background-color: rgba(255, 255, 255, 0.5) !important;
  padding: 4px !important;
  border-radius: 10px !important;
}

.swal2-container {
  z-index: 1400;
}

#pageHeaderIcon {
  color: white !important;
  padding: 8px !important;
  border-radius: 8px !important;
  font-size: 36px !important;
  margin-right: 10px;
  width: 65px !important;
  height: 65px !important;
  margin-top: 10px;
}

.css-1tgyln {
  box-shadow: none !important;
  background-color: #e9edee !important;
}

.css-vubbuv {
  color: #151d48 !important;
}
.css-1k455el.css-1yxmbwk {
  color: #151d48 !important;
}
.css-pio6kb {
  background-color: #ffffff !important;
  color: #151d48 !important;
  border-radius: 30px !important;
  margin-right: 35px !important;
}

.pageHeader {
  display: flex !important;
  align-items: center !important;
  justify-content: left !important;
  margin: 10px 0px !important;
}

#pageHeaderIcon .MuiSvgIcon-root {
  height: 45px !important;
  width: 45px !important;
}

.pageHeaderLogin {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  margin: 20px 0px 20px 0px !important;
  width: 100%;
  max-width: 600px;
}

.pageHeaderText {
  display: flex !important;
  flex-direction: column !important;
}

.pageHeaderTitle {
  color: #2c2126 !important;
  font-size: clamp(14px, 1.4vw, 36px);
  margin-bottom: 10px !important;
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  text-align: center;
}

.newUserItem label {
  margin-bottom: 8px;
}

.pageHeaderTitleinner {
  color: #2c2126 !important;
  font-size: clamp(14px, 1.4vw, 24px);
  font-family: 'Manrope';
  font-weight: 700;
  text-align: center;
  margin-left: 85px;
}

.CircularProgressbar-text {
  font-size: 16px !important;
}

.pageHeaderDescription {
  font-weight: 300 !important;
  font-family: 'Manrope';
  margin-bottom: 0px !important;
  color: #b5b3b4 !important;
  line-height: 24px !important;
  font-size: 18px !important;
  white-space: pre-line !important;
  text-align: center;
}

.pageTableTitle {
  font-weight: 600 !important;
  color: #1d2740 !important;
  font-size: 20px !important;
}

.pageTableTitleNoButton {
  font-weight: 600 !important;
  color: #1d2740 !important;
  font-size: 20px !important;
  margin-bottom: 15px !important;
}

.pageTableTopTitle {
  font-weight: 600 !important;
  color: #1d2740 !important;
  font-size: 20px !important;
  margin-bottom: 15px !important;
}

.MuiDataGrid-root {
  border: 1px solid #e6e7eb !important;
  border-radius: 20px !important;
  height: auto !important;
}

.MuiDataGrid-columnHeaderWrapper {
  background-color: #f9fafc !important;
}

.userListActions a {
  text-decoration: none !important;
}

.addButtonContainer a {
  text-decoration: none !important;
}

.addButtonText {
  width: 100% !important;
  max-width: 150px !important;
  justify-content: center !important;
  text-decoration: none !important;
  font-size: 18px !important;
  line-height: 20px !important;
  text-transform: none !important;
}

.addButtonTextLarge {
  width: 100% !important;
  max-width: 210px !important;
  justify-content: center !important;
  text-decoration: none !important;
  font-size: 18px;
  line-height: 20px;
  padding: 0px 10px;
  text-transform: none !important;
}

.addButtonTextSmall {
  width: 95px;
  justify-content: center;
  text-decoration: none !important;
  text-transform: none !important;
}

.addElementButton {
  background-color: #1d2740;
  padding: 8px 5px 8px 15px;
  font-size: 20px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  width: 150px;
  text-decoration: none !important;
}

.addElementIcon {
  text-decoration: none !important;
  background-color: rgba(255, 255, 255, 0.6);
  color: white;
  font-size: 30px !important;
  padding: 5px;
}

.addElementButtonPurple {
  background-color: #a700ff !important;
  padding: 8px 5px 8px 15px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 150px !important;
  text-decoration: none !important;
  margin-top: 30px !important;
  float: right !important;
}

.addElementButtonPurple:hover {
  background-color: rgba(167, 0, 255, 0.6);
}

.addElementButtonPurpleLarge {
  background-color: #a700ff !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 270px;
  text-decoration: none !important;
  margin-top: 0px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonPurpleLarge:hover {
  background-color: rgba(167, 0, 255, 0.6) !important;
}

.addElementButtonPurpleLarge.padding {
  padding: 8px 10px !important;
}

.addElementButtonPurple.padding {
  padding: 10px 10px !important;
}

.addElementButtonPurpleSmall.padding {
  padding: 10px 10px !important;
}

.addElementButtonPurpleMedium.padding {
  padding: 10px 10px !important;
}

.addElementButtonPurpleSmall {
  background-color: #a700ff !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 150px;
  text-decoration: none !important;
  margin-top: 30px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonPurpleSmall:hover {
  background-color: rgba(167, 0, 255, 0.6) !important;
}

.addElementButtonPurpleMedium {
  background-color: #a700ff !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 195px;
  text-decoration: none !important;
  margin-top: 30px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonPurpleMedium:hover {
  background-color: rgba(167, 0, 255, 0.6) !important;
}

.addElementButtonGreen {
  background-color: #22c55d !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 100% !important;
  max-width: 210px;
  text-decoration: none !important;
  margin-top: 0px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonGreen:hover {
  background-color: rgba(34, 197, 93, 0.6) !important;
}

.addElementButtonGreenSmall.padding {
  padding: 10px 10px !important;
}

.addElementButtonGreenSmall {
  background-color: #22c55d !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 150px;
  text-decoration: none !important;
  margin-top: 30px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonGreenSmall:hover {
  background-color: rgba(34, 197, 93, 0.6) !important;
}

.addElementButtonGreenLarge {
  background-color: #22c55d !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 270px;
  text-decoration: none !important;
  margin-top: 0px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonGreenLarge:hover {
  background-color: rgba(34, 197, 93, 0.6) !important;
}

.addElementButtonGreenLarge.padding {
  padding: 8px 10px !important;
}

.addElementButtonYellow.padding {
  padding: 8px 15px !important;
}

.addElementButtonRed.padding {
  padding: 8px 15px !important;
}

.addElementButtonRed.padding.margintop {
  padding: 8px 15px !important;
  margin-top: 30px !important;
}

.addElementButtonRedLarge.padding {
  padding: 8px 10px !important;
}

.addElementButtonRedMedium.padding {
  padding: 8px 10px !important;
}

.addElementButtonRed {
  background-color: #ef4444 !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 18px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 100% !important;
  max-width: 210px;
  text-decoration: none !important;
  margin-top: 0px !important;
  text-transform: none !important;
  font-size: 18px;
  line-height: 20px;
}

.addElementButtonRed:hover {
  background-color: rgba(239, 68, 68, 0.6) !important;
}

.addElementButtonRedLarge {
  background-color: #ef4444 !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 270px;
  text-decoration: none !important;
  margin-top: 0px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonRedLarge:hover {
  background-color: rgba(239, 68, 68, 0.6) !important;
}

.addElementButtonRedMedium {
  background-color: #ef4444 !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 195px;
  text-decoration: none !important;
  margin-top: 0px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonRedMedium:hover {
  background-color: rgba(239, 68, 68, 0.6) !important;
}

.addElementButtonRedSmall.padding {
  padding: 8px 15px !important;
}

.addElementButtonRedSmall {
  background-color: #ef4444 !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 150px;
  text-decoration: none !important;
  margin-top: 30px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonRedSmall:hover {
  background-color: rgba(239, 68, 68, 0.6) !important;
}

.addElementButtonYellow.padding {
  padding: 8px 15px !important;
}

.addElementButtonYellowSmall.padding {
  padding: 8px 15px !important;
}

.addElementButtonYellowLarge.padding {
  padding: 8px 10px !important;
}

.addElementButtonYellow {
  background-color: #f59e0b !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 18px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 100% !important;
  max-width: 210px;
  text-decoration: none !important;
  margin-top: 0px !important;
  text-transform: none !important;
  font-size: 18px;
  line-height: 20px;
}

.addElementButtonYellow:hover {
  background-color: rgba(245, 158, 11, 0.6) !important;
}

.addElementButtonYellowSmall {
  background-color: #f59e0b !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 150px;
  text-decoration: none !important;
  margin-top: 30px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonYellowSmall:hover {
  background-color: rgba(245, 158, 11, 0.6) !important;
}

.addElementButtonYellowMedium {
  background-color: #f59e0b !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 195px;
  text-decoration: none !important;
  margin-top: 30px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonYellowMedium:hover {
  background-color: rgba(245, 158, 11, 0.6) !important;
}

.leftmargin {
  margin-left: 30px !important;
}

.addElementButtonYellowLarge {
  background-color: #f59e0b !important;
  padding: 8px 5px 8px 5px !important;
  font-size: 20px !important;
  display: flex !important;
  align-items: center !important;
  border: none !important;
  border-radius: 5px !important;
  color: white !important;
  cursor: pointer !important;
  width: 270px;
  text-decoration: none !important;
  margin-top: 0px !important;
  float: right !important;
  text-transform: none !important;
}

.addElementButtonYellowLarge:hover {
  background-color: rgba(245, 158, 11, 0.6) !important;
}

.addElementIconPurple.css-i4bv87-MuiSvgIcon-root {
  text-decoration: none !important;
  color: #ffffff !important;
  font-size: 30px !important;
  padding: 5px;
  border-radius: 6px;
  stroke-width: 1 !important;
}

.addElementIconPurple.css-i4bv87-MuiSvgIcon-root:hover {
  color: #ffffff !important;
}

.addElementIconRed.css-i4bv87-MuiSvgIcon-root {
  text-decoration: none !important;
  color: #ffffff !important;
  font-size: 30px !important;
  padding: 5px;
  border-radius: 6px;
  stroke-width: 1 !important;
}

.addElementIconRed.css-i4bv87-MuiSvgIcon-root:hover {
  color: #ffffff !important;
}

.addElementIconYellow.css-i4bv87-MuiSvgIcon-root {
  text-decoration: none !important;
  color: #ffffff !important;
  font-size: 30px !important;
  padding: 5px;
  border-radius: 6px;
  stroke-width: 1 !important;
}

.addElementIconYellow.css-i4bv87-MuiSvgIcon-root:hover {
  color: #ffffff !important;
}

.addElementIconGreen.css-i4bv87-MuiSvgIcon-root {
  text-decoration: none !important;
  color: #ffffff !important;
  font-size: 30px !important;
  padding: 5px;
  border-radius: 6px;
  stroke-width: 1 !important;
}

.addElementIconGreen.css-i4bv87-MuiSvgIcon-root:hover {
  color: #ffffff !important;
}

.addElementIconYellowSmall.css-i4bv87-MuiSvgIcon-root {
  text-decoration: none !important;
  color: #ffffff !important;
  font-size: 30px !important;
  padding: 5px;
  border-radius: 6px;
  stroke-width: 1 !important;
}

.addElementIconYellowSmall.css-i4bv87-MuiSvgIcon-root:hover {
  color: #ffffff !important;
}

.pageSubHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
}

.actionButtonText {
  width: auto;
  margin-left: 5px;
  justify-content: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0px 0px;
  font-family: 'Manrope';
  font-style: normal;
  color: #000000;
}

footer .navactionButtonText {
  font-size: 16px;
}

.navactionButtonText {
  width: auto;
  margin-left: 5px;
  justify-content: center;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: 0px 0px;
  font-family: 'Manrope';
  font-style: normal;
  color: #2c2126 !important;
}

.navactionButtonText.active {
  color: #ef5663 !important;
}

.upgradebtn {
  background-color: #ef5663;
  padding: 2px;
  font-size: 16px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 20px;
  text-decoration: none !important;
  height: 40px;
  border-radius: 50px;
  padding: 10px 20px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.upgradebtn:hover {
  opacity: 0.8;
}

.actionElementButton {
  background-color: transparent;
  padding: 2px;
  font-size: 16px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  color: #1d2740;
  cursor: pointer;
  margin-right: 10px;
  text-decoration: none !important;
}

.actionElementButton:hover {
  opacity: 1;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding: 0px 6px 0px 0px !important;
}

.actionElementIcon {
  color: #1d2740;
  font-size: 20px !important;
  text-decoration: none;
}

.templateButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.templateButtonContainerWithPreview {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.templateButtonText {
  justify-content: center;
  text-decoration: none;
}

.templateElementButton {
  background-color: #ffffff;
  padding: 10px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  border: 1px solid #2c87de;
  border-radius: 10px;
  color: #2c87de;
  cursor: pointer;
  width: 100%;
  height: 55px;
  max-width: 200px;
  text-decoration: none !important;
}

.templateElementButton img {
  margin: 0px 15px 0px 15px;
}

.css-13cymwt-control {
  height: 50px;
  padding: 0px 5px 5px 0px;
  border: 1px solid #d8d5d6 !important;
  border-radius: 8px !important;
  color: #b5b3b4 !important;
  background-color: #ffffff !important;
  font-size: 15px;
  font-weight: 300;
  font-family: 'Manrope';
}

.popupElementButton {
  border: 1px solid #2c2126;
  padding: 5px 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  border-radius: 100%;
  color: #2c2126;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none !important;
}

.templateElementIcon {
  color: white;
  font-size: 20px !important;
  text-decoration: none;
  margin-right: 10px;
}

.popupTitle {
  color: #2c2126;
  font-weight: 600;
  font-size: 22px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 50px;
  font-family: 'Manrope';
}

.popupDropdown {
  width: 100%;
  height: 50px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #f9fafb;
  font-size: 16px;
}

.popupText {
  width: 100%;
  height: 50px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #d8d5d6 !important;
  border-radius: 8px;
  color: #b5b3b4 !important;
  background-color: #ffffff !important;
  font-size: 15px;
  font-weight: 300;
  font-family: 'Manrope';
  max-width: calc(100% - 15px) !important;
}

.passwordContainer {
  display: flex;
  margin-top: 15px;
}

.logoutButton {
  width: 100%;
  border-radius: 5px;
  background-color: rgb(254, 95, 95);
  border: none;
  margin-top: 30px;
  cursor: pointer;
  font-weight: 600;
  padding: 7px 10px;
  color: white;
  height: 46px;
  font-size: 18px;
}

.keepLoggedInButton {
  width: 100%;
  border-radius: 5px;
  background-color: #1d2740;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  font-weight: 600;
  padding: 7px 10px;
  color: white;
  height: 46px;
  font-size: 18px;
  margin-right: 5px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h4 {
  font-size: 22px;
  text-align: center;
}

.timerContainer #mypopup {
  display: none !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
  display: none;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox,
.MuiDataGrid-root .MuiDataGrid-cellCheckbox {
  display: none !important;
}

.sidebar-container {
  position: absolute;
  top: 0;
  left: 0;
  background: red;
  width: 40vw;
  height: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  color: #fff;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.App {
  position: relative;
  text-align: center;
}

* {
  box-sizing: border-box;
}

.css-ciy9n4-MuiPaper-root-MuiAppBar-root {
  background-color: #1d2740 !important;
  box-shadow: none !important;
  padding: 10px 0px;
}

.css-134qg7o-MuiButtonBase-root-MuiIconButton-root {
  margin-left: 0px !important;
  padding-top: 0px !important;

  padding-top: 8px !important;
  margin-right: 30px !important;
}

.css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  margin-left: 30px !important;
  padding-top: 0px !important;

  color: #2d264b !important;
  padding-top: 8px !important;
}

/* .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ef5663;
  stroke-width: 1;
} */

/* .css-i4bv87-MuiSvgIcon-root:hover {
  color: #ffffff !important;
  stroke: #ffffff;
  stroke-width: 1;
} */

/* .css-i4bv87-MuiSvgIcon-root.active {
  color: #ffffff !important;
  stroke: #ffffff;
  stroke-width: 1;
} */

/* button .css-i4bv87-MuiSvgIcon-root:hover {
  color: #ef5663 !important;
  stroke: #ffffff;
  stroke-width: 1;
} */

/* button .css-i4bv87-MuiSvgIcon-root.active {
  color: #ef5663 !important;
  stroke: #ef5663;
  stroke-width: 1;
} */

li#smssafetyid:hover {
  background-color: #ffa412;
}

li#smssafetyid.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ffa412;
  stroke-width: 1;
}

li#smssafetyid:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ffa412;
  stroke-width: 1;
}

li#inductionsid:hover {
  background-color: #a700ff;
}

li#inductionsid.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #a700ff;
  stroke-width: 1;
}

li#inductionsid:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #a700ff;
  stroke-width: 1;
}

li#fireid:hover {
  background-color: #ef4444;
}

li#fireid.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ef4444;
  stroke-width: 1;
}

li#fireid:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ef4444;
  stroke-width: 1;
}

li#foodid:hover {
  background-color: #22c55e;
}

li#foodid.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #22c55e;
  stroke-width: 1;
}

li#foodid:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #22c55e;
  stroke-width: 1;
}

li#remoteid:hover {
  background-color: #2f80ed;
}

li#remoteid.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #2f80ed;
  stroke-width: 1;
}

li#remoteid:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #2f80ed;
  stroke-width: 1;
}

.MuiCollapse-wrapperInner a#smssafetyid li {
  padding-left: 20px !important;
}

.MuiCollapse-wrapperInner a#inductionsid li {
  padding-left: 20px !important;
}

.MuiCollapse-wrapperInner a#foodid li {
  padding-left: 20px !important;
}

.MuiCollapse-wrapperInner a#fireid li {
  padding-left: 20px !important;
}

.MuiCollapse-wrapperInner a#overviewid li {
  padding-left: 20px !important;
}

.MuiCollapse-wrapperInner a#remoteid li {
  padding-left: 20px !important;
}

li#smssafetyid:hover {
  background-color: #ffa412 !important;
}

li#smssafetyid.active {
  background-color: #ffa412 !important;
}

li#inductionsid:hover {
  background-color: #a700ff !important;
}

li#inductionsid.active {
  background-color: #a700ff !important;
}

li#fireid:hover {
  background-color: #ef4444 !important;
}

li#fireid.active {
  background-color: #ef4444 !important;
}

li#foodid:hover {
  background-color: #22c55e !important;
}

li#foodid.active {
  background-color: #22c55e !important;
}

li#remoteid:hover {
  background-color: #2f80ed !important;
}

li#remoteid.active {
  background-color: #2f80ed !important;
}

.sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ffffff;
  stroke-width: 1;
}

#foodid .sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #22c55e;
  stroke-width: 1;
}

#foodid .sidebarlistItem.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #22c55e;
  stroke-width: 1;
}

#fireid .sidebarlistItem.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ef4444;
  stroke-width: 1;
}

#fireid .sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ef5663 !important;
  stroke: #ffffff;
  stroke-width: 1;
}

#overviewid .sidebarlistItem.active .css-i4bv87-MuiSvgIcon-root {
  color: #ef5663 !important;
  stroke: #ffffff;
  stroke-width: 1;
}

#overviewid .sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ef5663 !important;
  stroke: #ffffff;
  stroke-width: 1;
}

#smssafetyid .sidebarlistItem.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ffa412;
  stroke-width: 1;
}

#smssafetyid .sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ffa412;
  stroke-width: 1;
}

#inductionsid .sidebarlistItem.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #a700ff;
  stroke-width: 1;
}

#inductionsid .sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #a700ff;
  stroke-width: 1;
}

#remoteid .sidebarlistItem.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #2f80ed;
  stroke-width: 1;
}

#remoteid .sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #2f80ed;
  stroke-width: 1;
}

#usersid .sidebarlistItem.active .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ef5663;
  stroke-width: 1;
}

#usersid .sidebarlistItem:hover .css-i4bv87-MuiSvgIcon-root {
  color: #ffffff !important;
  stroke: #ef5663;
  stroke-width: 1;
}

.css-mrxozk-MuiDrawer-docked {
  width: 0px !important;
}

.css-1ij2uzp-MuiPaper-root-MuiAppBar-root {
  background-color: #1d2740 !important;
  box-shadow: none !important;
  padding: 10px 0px;
}

.css-dyjd4v-MuiDrawer-docked {
  width: auto !important;
}

.css-wuia68 {
  width: auto !important;
}

.css-pbatbg {
  background-color: transparent !important;
  box-shadow: none !important;
}

.buttonscontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttonscontainerfw {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
}

.buttonscontainerfwct {
  display: flex;
  flex-direction: column;
}

.buttonbox {
  flex: 2;
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 40px;
  border: 1px solid #ffffff;
}

.buttonboxfw {
  flex: 2;
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
}

.buttonboxfwgreen {
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
}

.buttonboxlarge {
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 40px;
  border: 1px solid #ffffff;
}

.buttonboxlarge .template-notes {
  display: flex;
  justify-content: space-between;
}

.buttonbox:nth-child(odd) {
  margin-right: 40px !important;
}

.buttonbox:last-child {
  margin-right: 0px;
}

.buttonboxfw:nth-child(odd) {
  margin-right: 0px;
}

.buttonboxfw:last-child {
  margin-right: 0px;
}

.buttonbox.purple:hover {
  background-color: #f5f3ff;
  border: 1px solid #a78bfa;
  cursor: pointer;
}

.buttonboxfw.purple:hover {
  background-color: #f5f3ff;
  border: 1px solid #a78bfa;
  cursor: pointer;
}

.buttonbox.green:hover {
  background-color: #f0fdf4;
  border: 1px solid #86efac;
  cursor: pointer;
}

.buttonbox.yellow:hover {
  background-color: #fefce8;
  border: 1px solid #f59e0b;
  cursor: pointer;
}

.buttonbox.darkblue:hover {
  background-color: #eff6ff;
  border: 1px solid #006be4;
  cursor: pointer;
}

.buttonboxheader {
  display: flex;
  align-items: center;
}

.buttonbox.purple .buttonboxheader .buttonboxicon {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  stroke: #ede9fe;
  stroke-width: 1;
  background-color: #ede9fe;
  border-radius: 6px;
  padding: 8px;
  color: #444a6d !important;
}

.buttonboxfw.purple .buttonboxheader .buttonboxicon {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  stroke: #ede9fe;
  stroke-width: 1;
  background-color: #ede9fe;
  border-radius: 6px;
  padding: 8px;
  color: #444a6d !important;
}

.buttonboxfw.green {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 12px;
  padding: 20px;
}

.buttonscontainerfwmain {
  display: flex;
  background-color: #f9fafb;
  border-radius: 12px;
  padding: 50px;
  justify-content: space-between;
}

.buttonscontainerfwtransparent {
  display: flex;
  background-color: transparent;
  border-radius: 12px;
  padding: 50px;
  justify-content: space-between;
}

.buttonboxfw.green .buttonboxheader .buttonboxicon {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  stroke: #f0fdf4;
  stroke-width: 1;
  background-color: #e2fff3;
  border-radius: 6px;
  padding: 8px;
  color: #444a6d !important;
}

.buttonbox.green .buttonboxheader .buttonboxicon {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  stroke: #f0fdf4;
  stroke-width: 1;
  background-color: #e2fff3;
  border-radius: 6px;
  padding: 8px;
  color: #444a6d !important;
}

.buttonbox.yellow .buttonboxheader .buttonboxicon {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  stroke: #fef9c3;
  stroke-width: 1;
  background-color: #fef9c3;
  border-radius: 6px;
  padding: 8px;
  color: #444a6d !important;
}

.buttonbox.darkblue .buttonboxheader .buttonboxicon {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  stroke: #dbeafe;
  stroke-width: 1;
  background-color: #dbeafe;
  border-radius: 6px;
  padding: 8px;
  color: #444a6d !important;
}

.buttonboxheader .buttonboxtitle {
  color: #151d48;
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
}

.buttonbox.purple:hover .buttonboxheader .buttonboxtitle {
  color: #a700ff;
}

.buttonbox.purple:active .buttonboxheader .buttonboxtitle {
  color: #a700ff;
}

.buttonbox.purple:hover .buttonboxheader .buttonboxicon {
  color: #a700ff !important;
}

.buttonboxfw.purple:hover .buttonboxheader .buttonboxtitle {
  color: #a700ff;
}

.buttonboxfw.purple:active .buttonboxheader .buttonboxtitle {
  color: #a700ff;
}

.buttonboxfw.purple:hover .buttonboxheader .buttonboxicon {
  color: #a700ff !important;
}

/* green */

.buttonbox.green:hover .buttonboxheader .buttonboxtitle {
  color: #4bd469;
}

.buttonbox.green:active .buttonboxheader .buttonboxtitle {
  color: #4bd469;
}

.buttonbox.green:hover .buttonboxheader .buttonboxicon {
  color: #4bd469 !important;
}

/* yellow */

.buttonbox.yellow:hover .buttonboxheader .buttonboxtitle {
  color: #ffa412;
}

.buttonbox.yellow:active .buttonboxheader .buttonboxtitle {
  color: #ffa412;
}

.buttonbox.yellow:hover .buttonboxheader .buttonboxicon {
  color: #ffa412 !important;
}

/* darkblue */

.buttonbox.darkblue:hover .buttonboxheader .buttonboxtitle {
  color: #006be4;
}

.buttonbox.darkblue:active .buttonboxheader .buttonboxtitle {
  color: #006be4;
}

.buttonbox.darkblue:hover .buttonboxheader .buttonboxicon {
  color: #006be4 !important;
}

.buttonboxbody {
  margin-top: 20px;
  color: #737791;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 100px;
}

.buttonboxbody h2 {
  color: #151d48;
  font-size: 22px;
  font-weight: 400;
  width: 100%;
  margin-bottom: 5px;
}

.buttonboxbody p {
  margin-bottom: 20px;
  width: 100%;
}

.buttonboxlarge .buttonboxbody {
  align-items: center;
  justify-items: center;
  display: flex;
  margin-bottom: 20px;
}

.buttonboxlarge .buttonboxbody input[type='checkbox'] {
  width: 28px;
  height: 28px;
  border: 1px solid #737791;
  border-radius: 12px;
  margin-right: 20px;
}

.buttonbox.purple:hover .buttonboxbody {
  color: #444a6d;
}

.buttonbox.green:hover .buttonboxbody {
  color: #444a6d;
}

.buttonbox.yellow:hover .buttonboxbody {
  color: #444a6d;
}

.buttonbox.darkblue:hover .buttonboxbody {
  color: #444a6d;
}

#inductionsid li:hover {
  background-color: #a700ff;
}

#inductionsid li.active {
  background-color: #a700ff !important;
}

#fireid li:hover {
  background-color: #ef4444;
}

#fireid li.active {
  background-color: #ef4444 !important;
}

#foodid li:hover {
  background-color: #22c55e;
}

#foodid li.active {
  background-color: #22c55e !important;
}

#smssafetyid li:hover {
  background-color: #ffa412;
}

#smssafetyid li.active {
  background-color: #ffa412 !important;
}

#remoteid li:hover {
  background-color: #2f80ed;
}

#remoteid li.active {
  background-color: #2f80ed !important;
}

.uploaddoccontainerbottom {
  display: flex;
}

.uploaddoccontainertop {
  display: flex;
}

.uploaddoccontainerleft {
  flex: 2;
}

.uploaddoccontainerright {
  flex: 2;
}

.UploadNowForm {
  max-width: 1240px;
}

.dashboardboxsmallcontainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboardboxsmall {
  padding: 20px;

  flex-direction: column;
  align-items: center;
  justify-items: center;
  width: 100%;

  background: #ffffff;
  display: block;
  text-align: center;
  border: 1px solid #d8d5d6;

  border-radius: 10px;
}

.dashboardboxsmall img {
  width: 100%;
  max-height: 70px;
}

.dashboardboxsmall:first-child {
  margin-left: 0px;
}

.dashboardboxsmall:last-child {
  margin-right: 0px;
}

.dashboardboxsmall h2 {
  color: #2c2126;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  margin: 5px 0px 5px 0px;
  font-family: 'Manrope';
  font-style: normal;
}

.dashboardboxsmall h3 {
  color: #2c2126;
  font-weight: 700;
  font-size: 2.8rem;
  line-height: 28px;
  margin: 25px 0px 25px 0px;
  font-family: 'Manrope';
  font-style: normal;
}

.dashboardboxsmall p {
  color: #b5b3b4;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 0px 0px;
  font-family: 'Manrope';
  font-style: normal;
}

.dashboardboxsmalllarge {
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0px;
  padding: 40px;
}

.dashboardboxsmalllarge .dashboard-charts-section {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  width: 100%;
}

.dashboardboxsmalllarge .dashboard-charts-section .dashboard-charts-inner {
  flex: 1 1 0;
  margin: 25px 0px;
}

.dashboardboxsmalllarge .dashboard-charts-section .dashboard-charts-inner .dashboard-charts-section .chartjs-render-monitor {
  width: 220px !important;
  height: 80px !important;
  margin: auto;
}

.dashboard-pie-chart .chartjs-render-monitor {
  width: 180px !important;
  height: 180px !important;
  margin: auto;
}

.dashboard-charts-section .chart-status {
  font-size: 12px;
  line-height: 14px;
  color: #a1a5b6;
  width: 100%;
  text-align: center;
  margin-bottom: 5px;
}

.chart-low-high {
  display: flex;
  width: 100%;
  margin-top: 5px;
}

.chart-low-high span {
  font-size: 10px;
  line-height: 12px;
  color: #a1a5b6;
  flex: 1;
  text-align: center;
}

.chart-title {
  margin-top: 15px;
}

.chart-title h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #151d48;
  margin-bottom: 5px;
}

.chart-title p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #737791;
}

/* dashboard charts */

.dashboardbox {
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 40px;
}

.dashboardboxsmallcontainerbottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-block-view {
  background: #ffffff;
  border-radius: 10px;
}

.dashboardboxsmallmed {
  background: #ffffff;
  border-radius: 10px;
  border-radius: 10px;
  /* display: flex; */
  margin: 25px 0px;
  width: 100%;
  padding: 40px;
}

.dashboardboxsmallmed:first-child {
  margin-right: 40px;
}

.small-chart-container {
  padding: 40px;
}

.datatablecontainer {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 12px;
  padding-bottom: 40px;
}

.safety-table .MuiDataGrid-root {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 12px !important;
}

.safety-table .MuiDataGrid-root .MuiDataGrid-columnsContainer {
  border-radius: 12px 12px 0px 0px !important;
}

.safety-table .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding: 10px !important;
  color: #151d48;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
}

.safety-table .MuiDataGrid-root .MuiDataGrid-cell {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  padding: 0px 18px !important;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
}

.inductions-table .MuiDataGrid-root {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 12px !important;
}

.inductions-table .MuiDataGrid-root .MuiDataGrid-columnsContainer {
  border-radius: 12px 12px 0px 0px !important;
}

.inductions-table .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  padding: 10px !important;
  color: #151d48;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
}

.inductions-table .MuiDataGrid-root .MuiDataGrid-cell {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  padding: 0px 18px !important;
  letter-spacing: 0.00938em;
  color: rgba(0, 0, 0, 0.87);
}

.datatable td {
  padding: 15px 30px;
  border-bottom: 1px solid #e2e2e2;
  border-left: 1px solid #e2e2e2;
  font-size: 16px;
}

.datatable tr.parent td {
  padding: 15px 30px;
  font-size: 18px;
}

.datatable td:first-child {
  border-left: 0px;
}

.datatable th {
  border-left: 1px solid #e2e2e2;
}

.datatable th:first-child {
  border-left: 0px;
}

/* .datatable tr {} */

.datatable tr.parent {
  background-color: #ffffff;
  color: #151d48;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.datatable tr.parent.purple {
  background-color: #f5f3ff;
  color: #151d48;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.datatable tr.parent.red {
  background-color: #fef2f2;
  color: #151d48;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.datatable tr.parent.yellow {
  background-color: #fefce8;
  color: #151d48;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.datatable tr.parent td {
  padding: 10px 30px;
}

.datatable th {
  padding: 30px 30px;
  color: #151d48;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.datatable th.smallHeader {
  padding: 30px 30px;
  color: #151d48;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
}

.datatable th:first-child {
  text-align: left;
}

.datatable td.extraheight {
  height: 800px;
  vertical-align: baseline;
}

.datatable.signature th:first-child {
  background-color: #f9fafb;
  border: none;
}

.datatable.signature th {
  background-color: #f9fafb;
  border-bottom: 1px solid #e2e2e2;
}

.datatable.signature td:first-child {
  background-color: #f9fafb;
  border: none;
}

.datatable.signature td:first-child {
  border: none;
}

.datatable {
  width: 100% !important;
}

.datatable.grey thead tr {
  width: 100% !important;
  background-color: #f9fafb;
  border-bottom: 1px solid #e2e2e2;
}

.datatable.grey thead tr th {
  border-bottom: 1px solid #e2e2e2 !important;
}

.datatable.grey td {
  border-left: 1px solid #e2e2e2 !important;
  border-bottom: 1px solid #e2e2e2 !important;
}

.sendNow {
  margin: 0px 65px;
  padding: 130px 40px;
}

/* .sendNowForm {
  display: flex; 
        flex-wrap: wrap;
} */

.sendNowItem {
  width: 600px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 35px;
}

.sendNowItem > label {
  margin-bottom: 15px;
  font-weight: 600;
  color: #1d2740;
  font-size: 16px;
}

.sendNowItem .templateButtonContainer > label {
  margin-bottom: 0px;
  font-weight: 600;
  color: #1d2740;
  font-size: 16px;
}

.sendNowItem span.mandatory {
  margin-bottom: 10px;
  font-weight: 600;
  color: red;
  font-size: 16px;
}

.sendNowItem > input {
  height: 50px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #f9fafb;
  font-size: 16px;
}

.sendNowItem > textarea {
  height: 250px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #f9fafb;
  font-size: 16px;
}

.sendNowButton {
  width: 100%;
  border-radius: 5px;
  background-color: #1d2740;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  font-weight: 600;
  padding: 7px 10px;
  color: white;
  height: 46px;
  font-size: 18px;
}

.buttoncontainers {
  display: flex;
  justify-content: space-evenly;
}

.confirmation-modal-inner {
  border-radius: 12px;
  background-color: #ffffff;
}

.confirmation-modal-inner .header-title h3 {
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 28px !important;
  color: #151d48 !important;
}

/* .confirmation-modal-footer .addElementIconYellow {} */

.confirmation-modal-footer button.addElementIconYellow {
  text-decoration: none !important;
  background-color: #f59e0b !important;
  color: #ffffff !important;
  font-size: 18px !important;
  padding: 5px;
  border-radius: 6px;
  padding: 10px 30px !important;
  border: 0px;
}

.confirmation-modal-footer button.addElementIconYellow:hover {
  opacity: 0.6;
}

.confirmation-modal-footer button.addElementIconRed {
  text-decoration: none !important;
  background-color: #ef4444 !important;
  color: #ffffff !important;
  font-size: 18px !important;
  padding: 5px;
  border-radius: 6px;
  padding: 10px 30px !important;
  border: 0px;
}

.confirmation-modal-footer button.addElementIconRed:hover {
  opacity: 0.6;
}

.createentryitem {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0px;
}

.createentryitem > label {
  margin-bottom: 15px;
  font-weight: 600;
  color: #1d2740;
  font-size: 16px;
}

.createentryitem .templateButtonContainer > label {
  margin-bottom: 0px;
  font-weight: 600;
  color: #1d2740;
  font-size: 16px;
}

.createentryitem span.mandatory {
  margin-bottom: 10px;
  font-weight: 600;
  color: red;
  font-size: 16px;
}

.createentryitem > input {
  height: 50px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #f9fafb;
  font-size: 16px;
  margin-right: 20px;
}

/* .createentryitem.nomarginright > select {

  margin-right: 0px !important;
} */

.createentryitem .popupDropdown {
  width: calc(100% - 20px);
}

.createentryitem > textarea {
  height: 250px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #f9fafb;
  font-size: 16px;
  width: calc(100% - 20px);
}

.sendNowButton {
  width: 100%;
  border-radius: 5px;
  background-color: #1d2740;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  font-weight: 600;
  padding: 7px 10px;
  color: white;
  height: 46px;
  font-size: 18px;
}

.nomarginright {
  margin-right: 0px !important;
}

.margin40right {
  margin-right: 40px !important;
}

.padding40right {
  padding-right: 40px !important;
}

.newUserItem select {
  height: 50px;
  padding: 5px 5px 5px 10px;
  border: 1px solid #cfd2db;
  border-radius: 5px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 300;
}

.makeStyles-drawerClose-7 {
  background-color: #ffffff !important;
  width: 85px !important;
  z-index: 10000 !important;
}

@media only screen and (max-width: 768px) {
  .makeStyles-drawerClose-7 {
    width: 90px !important;
  }

  .overview {
    padding: 90px 10px 90px 65px !important;
  }

  .dashboardboxsmalllarge {
    padding: 5px;
    margin: 0px;
    justify-content: center;
  }

  #logoutid svg.MuiSvgIcon-root {
    margin-left: 0px !important;
  }

  .MuiListItem-gutters {
    padding-left: 8px !important;
  }

  .dashboardboxsmall:first-child {
    margin-left: 10px;
  }

  .dashboardboxsmall:last-child {
    margin-right: 10px;
  }

  .dashboardboxsmalllarge {
    margin-right: 10px;
    margin-top: 10px;
  }

  .dashboardboxsmallmed:first-child {
    margin-right: 10px;
    margin-top: 20px;
  }

  .dashboardboxsmallmed {
    margin-top: 10px;
    margin-right: 10px;
  }
}

#gSigninButton {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider img {
  display: block;
  margin: 25px auto;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.MuiListItemIcon-root {
  min-width: none;
}

/* .MuiListItemText-root{padding-left:30px;} */

.logomain {
  width: 160px;
}

/* stripe  */
.paymentBody {
  height: 100vh;
  overflow-y: scroll;
  margin-top: 65px;
}

.header {
  text-align: center;
  line-height: 1.7;
}

.heading {
  font-weight: bold;
  color: #151d48;
}

.trial {
  color: #737791;
  font-size: 10.5px;
}

.paybuttons {
  display: flex;
  justify-content: center;
}

.paybutton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  border: 1px solid #d8d5d6;
  border-radius: 10px;
  background-color: white;
  padding: 5px;
}

.pays {
  background: #ef5663;
  border-radius: 8px;
  color: #ffffff;
  cursor: pointer;
  width: 150px;
  height: 56px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 54px;
  margin: 5px;
}

.pays1 {
  background: #ffedf0;
  border-radius: 8px;
  color: #ef5663;
  cursor: pointer;
  width: 150px;
  height: 56px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin: 5px;
  line-height: 54px;
}

.secondHeading {
  color: #737791;
  font-weight: 700;
  font-size: 10.5px;
}

.items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  margin: 30px;
  justify-content: center;
  max-width: fit-content;
  margin: 50px auto 0px auto;
}

.items .card:nth-child(1) {
  border: 1.5px solid #c026d3 !important;
  border-top: 20px solid #c026d3 !important;
}

.items .card:nth-child(1) .card__footer {
  background-color: #c026d3;
}

.items .card:nth-child(2) {
  border: 1.5px solid #3b82f6 !important;
  border-top: 20px solid #3b82f6 !important;
}

.items .card:nth-child(2) .card__footer {
  background-color: #3b82f6;
}

.items .card:nth-child(2) .features_list_item img {
  filter: invert(42%) sepia(77%) saturate(2657%) hue-rotate(203deg) brightness(100%) contrast(94%);
  color: #b5b3b4 !important;
}

.items .card:nth-child(3) {
  border: 1.5px solid #fb923c !important;
  border-top: 20px solid #fb923c !important;
}

.items .card:nth-child(3) .card__footer {
  background-color: #fb923c;
}

.items .card:nth-child(3) .features_list_item img {
  filter: invert(60%) sepia(90%) saturate(1101%) hue-rotate(334deg) brightness(105%) contrast(97%);
  color: #b5b3b4 !important;
}

.items .card:nth-child(4) {
  border: 1.5px solid #4f46e5 !important;
  border-top: 20px solid #4f46e5 !important;
}

.items .card:nth-child(4) .card__footer {
  background-color: #4f46e5;
}

.items .card:nth-child(4) .features_list_item img {
  filter: invert(23%) sepia(72%) saturate(2894%) hue-rotate(237deg) brightness(94%) contrast(92%);
  color: #b5b3b4 !important;
}

.card {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 380px;
  border: 1.5px solid #ef5663 !important;
  border-top: 20px solid #ef5663 !important;
  border-radius: 15px !important;
  padding: 20px;
  background-color: white;
}

.card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card__heading {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  width: 100%;
  line-height: 28px;
  text-transform: uppercase;
  /* identical to box height, or 140% */

  /* Greys/Blue Grey/900 */

  color: #2c2126;
}

.card__header p {
  font-size: 9px;
  padding: 2px;
  font-weight: 900;
  color: #151d48;
}

.card__body__price {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.card__body__price__heading {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  /* identical to box height, or 111% */

  /* Greys/Blue Grey/900 */

  color: #2c2126;
}

.card__body__price__amount {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  /* identical to box height, or 111% */

  /* Greys/Blue Grey/900 */

  color: #2c2126;
}

.card__body__price__duration {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */

  /* Greys/Blue Grey/800 */

  color: #4c4448;
}

.card__body__discription__heading {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Greys/Blue Grey/700 */

  color: #62585d;
}

.card__body__discription__heading {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Greys/Blue Grey/700 */

  color: #62585d;
}

.card__body__discription__heading_sub {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Greys/Blue Grey/700 */

  color: #62585d;
  margin: 15px 0px;
}

.card__footer {
  background: #ef5663;
  border-radius: 10px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  display: block;
  margin: 0px auto;
  height: 56px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Basic / White */

  color: #ffffff;
}

.card__footer__button {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  min-width: 200px;
  width: 100%;
  background-color: transparent;
  border: 0px;

  height: 56px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Basic / White */

  color: #ffffff;
  cursor: pointer;
}

.include,
.features {
  min-height: 300px;
}

.include__heading {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Greys/Blue Grey/700 */

  color: #62585d;
  margin: 15px 0px;
}

.include__heading .features__heading {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Greys/Blue Grey/700 */

  color: #62585d;
  margin: 15px 0px;
}

.include li,
.features li {
  list-style-type: none;
  line-height: 40px;
  margin: 10px auto;
}

.include span,
.features span {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #b5b3b4;
}

.tick {
  margin-right: 10px;
}

.include_list_item,
.features_list_item {
  display: flex;
  gap: 5px;
  justify-content: start;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .items {
    grid-template-columns: repeat(1, 1fr);
  }

  .makeStyles-drawerClose-7 {
    width: 70px !important;
  }

  .overview {
    padding: 90px 10px 90px 65px !important;
  }

  .dashboardboxsmalllarge {
    padding: 5px;
    margin: 0px;
    justify-content: center;
  }

  #logoutid svg.MuiSvgIcon-root {
    margin-left: 0px !important;
  }

  .MuiListItem-gutters {
    padding-left: 8px !important;
  }

  .dashboardboxsmall:first-child {
    margin-left: 10px;
  }

  .dashboardboxsmall:last-child {
    margin-right: 10px;
  }

  .dashboardboxsmalllarge {
    margin-right: 10px;
    margin-top: 10px;
  }

  .dashboardboxsmallmed:first-child {
    margin-right: 10px;
    margin-top: 20px;
  }

  .dashboardboxsmallmed {
    margin-top: 10px;
    margin-right: 10px;
  }
}

#gSigninButton {
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider img {
  display: block;
  margin: 25px auto;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.tooltip-trigger .tooltip {
  width: 100%;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  opacity: 0;
  transition: opacity 1s;
  position: absolute;
  z-index: 1;
}

.main-custom {
  transition:
    width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  flex: 5;
  padding: 80px 25px 0px 45px;
}

.makeStyles-appBarShift-2 span.pageHeaderTitleinner {
  margin-left: 30px;
}

.main-custom-open {
  width: calc(100% - 302px);
  transition:
    width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  /*margin-left: 302px;*/
  flex: 5 1;
  padding: 90px 25px 10px 26px;
}

/* ===== Scrollbar CSS ===== */

/* Chrome, Edge, and Safari */
/* *::-webkit-scrollbar {
  width: 12px;
  height: 12px;
} */

/* *::-webkit-scrollbar-track {
  background: #ffffff;
} */

/* *::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 10px;
  border: 3px solid #ffffff;
} */

.obj-fit-cover {
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}

/* .MuiDataGrid-main > div:last-child {
  display: none;
} */

.ck-editor__editable {
  border: 1px solid #ccced1 !important;
}

/* MUI switch css changes for admin panel START */
.MuiSwitch-thumb {
  width: 14px !important;
  height: 14px !important;
  margin-top: 4.5px !important;
}

.MuiSwitch-root {
  width: 58px !important;
  height: 42px !important;
}

.MuiSwitch-thumb {
  background-color: black !important;
  margin-left: 6px;
}

.Mui-checked .MuiSwitch-thumb {
  background-color: #fafafa !important;
  margin-left: 0px;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #fafafa !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #f50057 !important;
  opacity: 1 !important;
}

.MuiSwitch-track {
  border-radius: 15px !important;
}

.MuiIconButton-root:hover {
  background-color: none !important;
}

.MuiPaper-rounded {
  border-radius: 16px !important;
}

/* MUI switch css changes for admin panel END */
.btn-white-red {
  background-color: #fff !important;
  border: 1px solid #ef5864 !important;
  border-radius: 10px !important;
  color: #ef5864 !important;
  padding: 15px !important;
}

.btn-white-red:hover {
  color: #fff !important;
  background-color: #ef5864 !important;
}

.loading-container {
  display: block;
  position: fixed;
  background-color: lightgrey;
  z-index: 99999;
  opacity: 0.8;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.loading {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #ef5864;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  position: relative;
  left: calc(50% - 50px);
  top: 40%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
