.analytics{
    flex: 5;
    padding: 130px 40px;

}

.analytics .MuiBox-root{padding:0px;}

.tableButtonRow {
    display: flex;
    justify-content: end;
}

.exportbutton .exportbutton_other {
    border-radius: 0px !important;
}