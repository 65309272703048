.popup-custom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-inner-custom {
  position: relative;
  width: 100%;
  max-width: 650px;
  border-radius: 10px;
  background-color: white;
}

/* .popup-inner-custom .close-btn {
  position: absolute;
  top: 16px;
  right: 0;
} */

.popupElementButton-custom {
  border: 1px solid transparent;
  padding: 5px 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
  border-radius: 100%;
  color: white;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none !important;
}

.header-custom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #ef5663;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.header-wrapper {
  width: 100%;
}

.btn-popup {
  border: 1px solid transparent;
  background-color: transparent;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.btn-popup:hover {
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
